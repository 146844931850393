import request from '../utils/request'

// API-F81 - [UserOrdersController] userOrder/list 購買列表or賣出列表
export function getOrderList (params) {
  return request({
    url: `/userOrder/list`,
    method: 'get',
    params
  })
}

export function editOrder (data) {
  return request({
    url: `/userOrder/editShipping`,
    method: 'post',
    data
  })
}
