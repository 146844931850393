<template>
  <div class="tab-content col-lg-10 col-md-10">
    <div class="iq-card-body p-0">
      <div class="row">
        <div>
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('menu.order')}}</h4>
            </template>
            <template v-slot:body>
              <div class="mb-3">
                <router-link :to="`/user/order-buy`">
                  <el-button type="info" round plain>購買紀錄</el-button>
                </router-link>
                &nbsp;&nbsp;
                <router-link v-if=" user.user_type === 2" :to="`/user/order-sale`">
                  <el-button type="primary" round>賣出紀錄</el-button>
                </router-link>
              </div>

              <div class="allow-scroall" id="scrollbar-style-1">
                <table class="b-table m-responsive m-earnings">
                  <thead>
                    <tr>
                      <th>{{$t('訂單編號')}}</th>
                      <!-- <th>{{$t('訂購日期')}}</th> -->
                      <th>{{$t('訂單金額')}}</th>
                      <th>{{$t('配送方式')}}</th>
                      <th>{{$t('付款狀態')}}</th>
                      <th>{{$t('出貨狀態')}}</th>
                      <th>{{$t('購買人資訊')}}</th>
                      <th>{{$t('訂單產品詳細')}}</th>
                    </tr>
                  </thead>
                  <tbody class="table-c">
                    <tr v-for="(item, index) in list" :key="index">
                      <td :data-title="$t('訂單編號')" class="b-table__number">
                        <span>訂單編號：{{item.order_sn}}</span><br>
                        <span>寄件編號：{{item?.ezship_receive?.sn_id}}</span><br>
                        <span>訂購日期：{{item.create_time}}</span>

                      </td>
                      <!-- <td :data-title="$t('訂購日期')" class="b-table__date">
                        <span>{{item.create_time}}</span>
                      </td> -->
                      <td :data-title="$t('訂單金額')" class="b-table__price">
                        <span>{{item.order_amount}}</span>
                      </td>
                      <td :data-title="$t('配送方式')" class="b-table__shipping">
                        <span>{{item.shipping_name}}</span>
                      </td>
                      <td :data-title="$t('付款狀態')" class="b-table__pay_status">
                        <span>{{item.pay_status_name}}</span>
                      </td>
                      <td :data-title="$t('出貨狀態')" class="b-table__shipping_status">
                        <span v-if="item.shipping_status === 1">
                          <el-button @click="actionShipping(item)" type="primary" round plain>出貨</el-button>
                        </span>
                        <span class="text-primary" v-else>{{item.shipping_status_name}}</span>
                      </td>
                      <td :data-title="$t('購買人資訊')" class="b-table__buyer">
                        <span>{{item.buyer_info.consignee}}</span><br>
                        <span>{{item.buyer_info.phone}}</span><br>
                        <span>{{item.buyer_info.email}}</span><br>
                        <span>{{item.buyer_info.receive}}</span>
                      </td>
                      <!-- <td :data-title="$t('購買人資訊')" class="b-table__buyer text-center py-3">
                        <el-button circle type="primary" plain data-bs-toggle="modal" data-bs-target="#modalsDetail" @click="toggleModalContent('buyer',item.buyer_info)"><i class="ri-search-line"></i></el-button>
                      </td> -->
                      <td :data-title="$t('訂單產品詳細')" class="b-table__goods text-center py-3">
                        <!-- <el-button circle type="primary" plain data-bs-toggle="modal" data-bs-target="#modalsDetail" @click="toggleModalContent('goods',item.order_goods)"><i class="ri-search-line"></i></el-button> -->
                        <div v-for="item in item.order_goods" :key="item.value" :value-key="value"> <span>* {{ item.goods_name }}</span><br></div>
                      </td>
                    </tr>
                    <tr v-if="!list.length" class="table-emtpy-text">
                      <td colspan="8" class="text-center">{{ $t('此列表為空') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </div>
  <modal ref="modals" id="modalsDetail" dialogClass="modal-fullscreen-sm-down" tabindex="-1" :title="$t('label.add')+$t('label.post')" aria-labelledby="modalsLabel" aria-hidden="true">
    <model-header>
      <h5 v-if="modalType==='buyer'" class="modal-title">{{$t('購買人資訊')}}</h5>
      <h5 v-else class="modal-title">{{$t('訂單產品詳細')}}</h5>
      <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">
        <i class="ri-close-fill"></i>
      </button>
    </model-header>
    <model-body>
      <div v-if="modalType==='buyer'">
        <div class="">
          <p>收件人：{{ modalDetail.consignee }}</p>
          <p>電話：{{ modalDetail.phone }}</p>
          <p>收件超商：{{ modalDetail.receive }}</p>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-between border-bottom border-2 fw-bold p-2">
          <span>商品名稱</span>
          <span>價格</span>
        </div>
        <div v-for="(item, index) in modalDetail" class="d-flex justify-content-between border-bottom p-2" :key="index">
          <span>{{ item.goods_name }}</span>
          <span>{{ item.goods_price }}</span>
        </div>
      </div>
    </model-body>
  </modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getOrderList, editOrder } from '@/api/order'

export default {
  name: 'OrderList',
  props: ['menuId'],
  data() {
    return {
      primaryKey: 'created_at',
      commomData: commomData(),
      selectedId: 1,
      list: [],
      goodsDetail: []
    }
  },
  methods: {
    actionShipping(item) {
      if (!confirm(`確認要將此訂單設定為出貨！`)) {
        return
      }
      const d = {
        order_sn: item.order_sn
      }
      editOrder(d)
        .then(res => {
          console.log(res)
          this.getRows()
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_type = 2
      this.commomData.condition.page_size = 999
      this.commomData.condition.order_by = this.primaryKey
      getOrderList(this.commomData.condition)
        .then(res => {
          this.list = res.data
          // if (this.commomData.condition.page === 1) {
          //   this.list = res.data
          //   this.commomData.condition.page = 2
          //   this.commomData.listLoader = false
          //   if (res.total === 0) {
          //     this.commomData.noResult = true
          //   }
          // } else if (res.total > this.list.length) {
          //   this.list.push(...res.data)
          //   this.commomData.condition.page = this.commomData.condition.page + 1
          //   this.commomData.listLoader = false
          // } else if (res.total === this.list.length) {
          //   this.commomData.noResult = true
          //   this.commomData.listLoader = false
          // }
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    toggleModalContent(item) {
      this.goodsDetail = item
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  created() {},
  mounted() {
    this.getRows()
  }
}
</script>
